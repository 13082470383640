import React from "react";
import PropTypes from "prop-types";
import { GatsbyImage as Img } from "gatsby-plugin-image";
import get from "lodash/get";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { graphql, Link } from "gatsby";
import slugify from "../../gatsby/util/slugify";

import Layout from "../components/layout";
import SiteNav from "../components/SiteNav";
import SiteFooter from "../components/SiteFooter";
import Sections from "../components/Headers/Sections";

import PostFeaturedImage from "../components/PostFeaturedImage";
import Video from "../components/Video";
import ReadMoreHTML from "../components/ReadMoreHTML";
import EntryHeader from "../components/Headers/Entry";
import ContentTheme from "../components/ContentTheme";
import InsetContent from "../components/InsetContent";
import { TagList, Tag } from "../components/TagList";
import FullBleed from "../components/FullBleed";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import faNewer from "@fortawesome/fontawesome-free-solid/faFastForward";
import faOlder from "@fortawesome/fontawesome-free-solid/faFastBackward";

const PaginationContainer = styled.div`
	background: #efebf4;
	border-top: 1px solid #d8cee5;
`;

const Pagination = styled.div`
	display: flex;
	justify-content: space-between;
	padding-top: 2em;
	padding-bottom: 2em;
	font-weight: bold;
	a {
		text-decoration: none;
	}
`;

const PaginationIcon = styled(FontAwesomeIcon)`
	height: 0.75em;
	top: -0.125em;
	position: relative;
	margin-left: 4px;
	margin-right: 4px;
`;

const Article = styled.article`
	background: ${(props) => props.theme.contentBackground};
	border-bottom: ${(props) => props.theme.contentBottomBorder};
	padding-top: ${(props) => (props.image ? "0" : "2em")};
	padding-bottom: 2em;
`;

const PostContent = styled(ReadMoreHTML)`
	color: ${(props) => props.theme.contentColor};
`;

const PhotoImg = styled(FullBleed)`
	margin-bottom: 2em;
	margin-top: 2em;
	box-shadow: ${(props) => props.theme.contentBoxShadow || "none"};
`;

class Categories extends React.Component {
	render() {
		const siteTitle = get(this, "props.data.site.siteMetadata.title");
		const posts = get(this, "props.data.allMarkdownRemark.edges") || [];
		const image = get(this, "props.data.header");
		const category = get(this, "props.pageContext.category");
		const { next, prev } = get(this, "props.pageContext");
		const permalink =
			(category && "/category/" + slugify(category)) || null;
		return (
			<Layout>
				<div>
					<SiteNav />
					{image && (
						<PostFeaturedImage
							height={"7em"}
							image={image.childImageSharp.gatsbyImageData}
						/>
					)}
					<Sections />
					<Helmet title={siteTitle} />
					{permalink && (
						<Helmet key="permalink">
							<link
								rel="self"
								href={"https://stevestreza.com" + permalink}
							/>
						</Helmet>
					)}
					{posts.map(({ node }) => {
						const title = get(node, "frontmatter.title");
						return (
							<ContentTheme
								key={node.id}
								layout={node.frontmatter.layout}
							>
								<Article
									layout={node.frontmatter.layout || "post"}
									className={
										"h-entry " +
										"layout-" +
										(node.frontmatter.layout || "post")
									}
									key={node.fields.slug}
									image={node.frontmatter.image}
								>
									{node.frontmatter.layout === "photo" &&
										node.frontmatter.image && (
											<InsetContent>
												<PhotoImg>
													<Img
														image={
															node.frontmatter
																.image
																.childImageSharp
																.gatsbyImageData
														}
													/>
												</PhotoImg>
											</InsetContent>
										)}
									<EntryHeader
										slug={node.fields.slug}
										title={title}
										date={node.frontmatter.date}
										link={node.frontmatter.link}
										image={
											node.frontmatter.image &&
											node.frontmatter.layout !==
												"photo" &&
											node.frontmatter.image
												.childImageSharp.gatsbyImageData
										}
									/>
									<InsetContent>
										{node.frontmatter.video_url && (
											<section>
												<Video
													url={
														node.frontmatter
															.video_url
													}
												/>
											</section>
										)}
										<PostContent
											className="e-content"
											html={node.html}
											slug={node.fields.slug}
											link={node.frontmatter.link}
										/>
										{node.frontmatter.tags && (
											<TagList>
												{node.frontmatter.tags.map(
													(tag) => (
														<Tag
															key={tag}
															tag={tag}
														/>
													)
												)}
											</TagList>
										)}
									</InsetContent>
								</Article>
							</ContentTheme>
						);
					})}
					{(next || prev) && (
						<PaginationContainer>
							<InsetContent width={900}>
								<Pagination>
									{next && (
										<Link rel="next" to={next}>
											<PaginationIcon icon={faOlder} />
											{" Older Posts"}
										</Link>
									)}
									{prev && (
										<Link rel="prev" to={prev}>
											{"Newer Posts "}
											<PaginationIcon icon={faNewer} />
										</Link>
									)}
								</Pagination>
							</InsetContent>
						</PaginationContainer>
					)}
					<SiteFooter />
				</div>
			</Layout>
		);
	}
}

Categories.propTypes = {
	pageContext: PropTypes.shape({
		category: PropTypes.string.isRequired,
	}),
	data: PropTypes.shape({
		allMarkdownRemark: PropTypes.shape({
			edges: PropTypes.arrayOf(
				PropTypes.shape({
					node: PropTypes.shape({
						frontmatter: PropTypes.shape({
							title: PropTypes.string.isRequired,
						}),
					}),
				}).isRequired
			),
		}),
	}),
};

export const query = graphql`
	query CategoryPage($category: String) {
		site {
			siteMetadata {
				title
			}
		}
		allMarkdownRemark(
			limit: 10
			sort: { fields: [frontmatter___date], order: DESC }
			filter: { frontmatter: { categories: { in: [$category] } } }
		) {
			edges {
				node {
					id
					html
					fields {
						slug
					}
					frontmatter {
						date(formatString: "MMMM DD, YYYY")
						dateISO8601: date(
							formatString: "YYYY-MM-DD HH:mm:ss.SSSZZ"
						)
						title
						video_url
						link
						layout
						tags
						categories
						image {
							childImageSharp {
								gatsbyImageData(layout: FULL_WIDTH)
							}
						}
					}
				}
			}
		}

		header: file(relativePath: { eq: "header/header.png" }) {
			...ImageData
		}
	}
`;

export default Categories;
