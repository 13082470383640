import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { GatsbyImage as Img } from "gatsby-plugin-image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import faLink from "@fortawesome/fontawesome-free-solid/faLink";
import faCalendarCheck from "@fortawesome/fontawesome-free-solid/faCalendarCheck";

import InsetContent from "../InsetContent";
import PostFeaturedImage from "../PostFeaturedImage";

const Icon = styled(FontAwesomeIcon)`
	height: 0.5em;
	line-height: 2em;
	top: -0.25em;
	position: relative;
	margin-right: 4px;
`;

const Header = styled.header`
	margin-bottom: ${(props) => (props.noMargin ? "0" : "2em")};
	position: relative;
`;

const Time = styled.time`
	font-weight: bold;
	margin-top: 1em;
	display: block;
	color: ${(props) => props.theme.titleColor || props.theme.contentColor};
	text-shadow: ${(props) => props.theme.contentShadow || "none"};
`;

const StyledLink = styled(Link)`
	text-decoration: none;
	&:hover {
		text-decoration: underline;
	}
	text-shadow: ${(props) => props.theme.contentShadow || "none"};
	background: none;

	color: ${(props) => props.theme.titleColor || props.theme.contentColor};
`;

const StyledA = styled.a`
	text-decoration: none;
	&:hover {
		text-decoration: underline;
	}
	text-shadow: ${(props) => props.theme.contentShadow || "none"};
	background: none;

	color: ${(props) => props.theme.titleColor || props.theme.contentColor};
`;

const HeaderImage = styled(Img)`
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 15em;
`;

const Title = styled.h1`
	margin: 0;

	color: ${(props) => props.theme.titleColor || props.theme.contentColor};
`;

const ImageOverlay = styled.div`
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
`;

const HasImage = ({ title, slug, date, dateISO8601, isRoot }) => {
	const contents = (
		<ImageOverlay>
			<InsetContent>
				<Title>{title}</Title>
				<Time dateTime={dateISO8601} className="dt-published">
					{date}
				</Time>
			</InsetContent>
		</ImageOverlay>
	);

	if (isRoot) {
		return contents;
	} else {
		return <StyledLink to={slug}>{contents}</StyledLink>;
	}
};

const NoImage = ({ title, slug, date, link, dateISO8601, type = "post" }) => {
	return (
		<InsetContent>
			{title && (
				<Title>
					{link && (
						<StyledA
							className={
								(type === "post" ? "u-repost-of " : "") +
								"p-name"
							}
							target="_blank"
							href={link}
						>
							{type === "rsvp" && <Icon icon={faCalendarCheck} />}
							{type === "post" && <Icon icon={faLink} />}
							{title}
						</StyledA>
					)}
					{!link && (
						<StyledLink className="p-name" to={slug}>
							{title}
						</StyledLink>
					)}
				</Title>
			)}
			<StyledLink className="u-url" to={slug}>
				<Time className="dt-published" dateTime={dateISO8601}>
					{date}
				</Time>
			</StyledLink>
		</InsetContent>
	);
};

const EntryHeader = ({ children, image, ...props }) => {
	if (!children && !image) {
		return (
			<Header {...props}>
				<NoImage {...props} />
			</Header>
		);
	}

	if (props.link) {
		return <Header {...props} />;
	}

	const imageEl = (
		<PostFeaturedImage height={"22em"} image={image} {...props}>
			{children}
		</PostFeaturedImage>
	);

	let wrappedImage = props.isRoot ? (
		imageEl
	) : (
		<StyledLink to={props.slug}>{imageEl}</StyledLink>
	);

	return <Header {...props}>{wrappedImage}</Header>;
};
export default EntryHeader;
